<template>
  <div style="width: 100%; height: 100%;">
    <pdf
        v-for="item in pageTotal"
        :src="pdfUrl"
        :key="item"
        :page="item"/>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  name: "index",
  components: {pdf},
  data() {
    return {
      pageTotal: 0,
      pdfUrl: '',
    }
  },

  mounted() {
    this.getTotal()
  },

  methods: {
    // 获取pdf总页数
    getTotal() {
      // 多页pdf的src中不能直接使用后端获取的pdf地址 否则会按页数请求多次数据
      // 需要使用下述方法的返回值作为url
      //this.pdfUrl = pdf.createLoadingTask('http://m.weihuazhou.top/artisan/file/view/20231116/1pdfview.pdf')
      this.pdfUrl = pdf.createLoadingTask('http://m.weihuazhou.top/artisan/file/view/20231116/word.docx')
      // 获取页码
      this.pdfUrl.promise.then(pdf => this.pageTotal = pdf.numPages).catch(error => {})
    },
  }
}
</script>

<style scoped>

</style>
